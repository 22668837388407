import React from "react"
import { Row, Col, Card, CardBody, CardTitle, Label, Button } from "reactstrap"

const BasicInfo = ({
  validationType,
  setIsOpen,
  offerProduct,
  setIsReplacementProducts,
  setIsExtraOpen,
  setExtraProducts,
  extraProducts,
}) => {
  return (
    <div>
      <div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle>Basic Info</CardTitle>
                <div>
                  <div className="mb-3">
                    <Label className="form-label">Status</Label>
                    <select
                      name="active"
                      className="form-select"
                      onChange={event => {
                        validationType.setFieldValue(
                          event.target.name,
                          JSON.parse(event.target.value)
                        )
                      }}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.active.toString()}
                    >
                      <option value={true.toString()}>Active</option>
                      <option value={false.toString()}>Inactive</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Product</Label>
                    {offerProduct?.length > 0 && (
                      <div>
                        {offerProduct?.map((product, index) => {
                          return (
                            <div
                              className="selected-product-item"
                              key={index}
                              style={{
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <img src={product.images?.[0]?.src} />
                                <Label className="form-label">
                                  {product.title}
                                </Label>
                              </div>
                              <Label className="form-label">
                                Product ID:{" "}
                                {product?.vendor_product?.product_id ||
                                  product?.shopifyId}
                              </Label>
                            </div>
                          )
                        })}
                      </div>
                    )}
                    <div className="offer-product-container">
                      <Button
                        color="primary"
                        type="button"
                        data-toggle="modal"
                        data-target="#myModal"
                        onClick={() => {
                          setIsReplacementProducts(false)
                          setIsOpen(true)
                        }}
                      >
                        Select A Product
                      </Button>
                    </div>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Extra Product</Label>
                    {extraProducts?.length > 0 && (
                      <div>
                        {extraProducts?.map((product, index) => {
                          return (
                            <div
                              className="selected-product-item"
                              key={index}
                              style={{ justifyContent: "space-between" }}
                            >
                              <div>
                                <img src={product.images?.[0]?.src} />
                                <Label className="form-label">
                                  {product.title}
                                </Label>
                              </div>
                              <i
                                className="mdi mdi-delete"
                                style={{ fontSize: 23, cursor: "pointer" }}
                                onClick={() => {
                                  setExtraProducts(extraProducts_ => {
                                    const updatedProducts = extraProducts_
                                      ? extraProducts_.filter(
                                          el => el.id !== product.id
                                        )
                                      : []
                                    return updatedProducts
                                  })
                                }}
                              ></i>
                            </div>
                          )
                        })}
                      </div>
                    )}
                    <div className="offer-product-container">
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => {
                          setIsExtraOpen(true)
                        }}
                      >
                        Select A Product
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default BasicInfo
