import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Modal, ModalBody } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { callPostApi } from "../../utils/api"
import { showErrorToastr } from "../../components/Common/toastr"
import { useFunnelData } from "../../contexts/FunnelDataContext"
import { initialShopEnv, allShopsEnv } from "../../utils/shop"
import { countryList } from "utils/country"

const offerTypes = [
  { label: "Store", value: "store" },
  { label: "Acquisition Main", value: "acquisition_main" },
  { label: "RMKT", value: "rmkt" },
]

const CreateEditFunnelModal = ({
  show,
  onCloseClick,
  funnel,
  isDuplicating,
  onSaveSuccess,
  modalTitle,
}) => {
  const navigate = useNavigate()
  const [title, setTitle] = useState("")
  const [email, setEmail] = useState("")
  const [description, setDescription] = useState("")
  const [isActive, setIsActive] = useState("")
  const { updateFunnelData } = useFunnelData()
  const [errors, setErrors] = useState({
    title: false,
    description: false,
    email: false,
  })
  const [store, setStore] = useState(initialShopEnv[process.env.REACT_APP_ENV])
  const [country, setCountry] = useState("US")
  const [offerType, setOfferType] = useState("store")
  const [campaignIds, setCampaignIds] = useState([])
  const [checkout, setCheckout] = useState(null)
  const [url, setUrl] = useState("")
  const [externalFunnelId, setExtenalFunnelId] = useState("")

  useEffect(() => {
    if (funnel) {
      setTitle(funnel.title || "")
      setDescription(funnel.description || "")
      setStore(funnel.store)
      setCountry(funnel.country || "")
      setOfferType(funnel.type || funnel.offer_type || "")
      setCampaignIds(funnel.campaign_ids || [])
      setUrl(funnel?.funnel_url || "")
      setExtenalFunnelId(funnel.external_funnel_id || "")
      setCheckout(funnel.checkout)
      setIsActive(funnel.is_active)
    } else {
      setTitle("")
      setDescription("")
    }
  }, [funnel])

  const validateAndSetErrors = () => {
    const isValidEmail = validateEmail(email)
    const errors = {
      title: title === "",
      email: !isValidEmail,
      store: !store,
    }

    setErrors(errors)
    return errors.title || errors.email || errors.store
  }

  const createFunnel = () => {
    const newFunnelData = {
      title,
      description,
      email,
      store,
      country,
      type: offerType,
      checkout,
      funnel_url: url,
      xml: "",
    }
    updateFunnelData(newFunnelData)
    navigate(`/funnels/draw-your-funnel/create`)
  }

  const updateFunnel = funnelToUpdate => {
    const updatedFunnelData = {
      id: funnelToUpdate.id,
      title,
      description,
      isActive,
      email,
      country,
      type: offerType,
      funnel_url: url,
      checkout,
      xml: "",
    }
    updateFunnelData(updatedFunnelData)
    navigate(`/funnels/draw-your-funnel/edit/${funnelToUpdate.id}`)
  }

  const duplicateFunnel = originalFunnel => {
    const data = {
      originalFunnelId: originalFunnel.id,
      title,
      description,
      created_by: email,
      store,
      country,
      funnel_url: url,
      type: offerType,
      checkout,
    }

    callPostApi(true, "/funnel/duplicate", data, finalizeSave, handleError)
  }

  const handleError = errorMessage => {
    showErrorToastr(`Failed to process funnel: ${errorMessage}`)
  }

  const handleSave = () => {
    if (validateAndSetErrors()) {
      return
    }

    if (funnel) {
      if (isDuplicating) {
        duplicateFunnel(funnel)
      } else {
        updateFunnel(funnel)
      }
    } else {
      createFunnel()
    }
  }

  const resetForm = () => {
    setTitle("")
    setEmail("")
    setDescription("")
    onCloseClick()
  }

  const finalizeSave = () => {
    resetForm()
    onSaveSuccess()
  }
  const validateEmail = email => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/
    return regex.test(email)
  }

  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5">
          <button
            type="button"
            onClick={onCloseClick}
            className="btn-close position-absolute end-0 top-0 m-3"
          ></button>
          <div className="mb-4">
            <p style={{ fontSize: "16px" }}>{modalTitle}</p>
          </div>
          <div className="mb-4">
            <label>Email*</label>
            <input
              type="text"
              className={`form-control ${errors.email ? "is-invalid" : ""}`}
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            {errors.email && (
              <div className="invalid-feedback">
                {email.length > 0
                  ? "Please enter a valid email address"
                  : "Email is required"}
              </div>
            )}
          </div>

          <div className="mb-4">
            <label>Product*</label>
            <input
              type="text"
              className={`form-control ${errors.title ? "is-invalid" : ""}`}
              placeholder="Product"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            {errors.title && (
              <div className="invalid-feedback">Product is required</div>
            )}
          </div>

          <div className="mb-4">
            <label>Store*</label>
            <div className="select-wrapper">
              <select
                className="form-control select-store"
                value={store}
                onChange={e => {
                  setStore(e.target.value)
                }}
                disabled={!isDuplicating && funnel}
              >
                {allShopsEnv[process.env.REACT_APP_ENV]?.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <i className="fa fa-angle-down angle-icon"></i>
            </div>
          </div>

          <div className="mb-4">
            <label>Shopify Tag</label>
            <input
              type="text"
              className={`form-control ${errors.tag ? "is-invalid" : ""}`}
              placeholder="Tag"
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label>Country*</label>
            <div className="select-wrapper">
              <select
                className="form-control select-country"
                value={country.value}
                onChange={e => {
                  setCountry(e.target.value)
                }}
              >
                {countryList?.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <i className="fa fa-angle-down angle-icon"></i>
            </div>
          </div>

          <div className="mb-4">
            <label>Type*</label>
            <div className="select-wrapper">
              <select
                className="form-control select-country"
                value={offerType?.value}
                onChange={e => {
                  setOfferType(e.target.value)
                }}
              >
                {offerTypes?.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <i className="fa fa-angle-down angle-icon"></i>
            </div>
          </div>

          <div className="mb-4">
            <label>Checkout ID</label>
            <input
              type="text"
              className={`form-control ${errors.tag ? "is-invalid" : ""}`}
              placeholder="Checkout ID"
              value={checkout}
              onChange={e => setCheckout(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label>URL</label>
            <input
              type="text"
              className={`form-control ${errors.tag ? "is-invalid" : ""}`}
              placeholder="URL"
              value={url}
              onChange={e => setUrl(e.target.value)}
            />
          </div>

          <div className="mb-4">
            <label>Campaign IDs</label>
            <textarea
              className={`form-control`}
              placeholder="Enter Campaign IDs, separated by commas"
              value={campaignIds.join(", ")}
              onChange={(e) =>
                setCampaignIds(
                  e.target.value
                    .split(",")
                    .map((id) => id.trim())
                    .filter((id) => id !== "")
                )
              }
              rows="3"
            />
          </div>

          <div className="mb-4">
            <label>External Funnel ID</label>
            <input
              type="text"
              className={`form-control ${errors.tag ? "is-invalid" : ""}`}
              placeholder="External Funnel ID"
              value={externalFunnelId}
              onChange={e => setExtenalFunnelId(e.target.value)}
            />
          </div>

          <div className="hstack gap-2 justify-content-end mb-0">
            <button
              type="button"
              className="btn btn-danger"
              style={{
                backgroundColor: "#142541",
                border: "none",
                borderRadius: "8px",
                padding: "8px 16px",
              }}
              onClick={handleSave}
            >
              Save
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              style={{
                backgroundColor: "#EFEFEF",
                border: "none",
                borderRadius: "8px",
                color: "#767676",
                padding: "8px 16px",
              }}
              onClick={onCloseClick}
            >
              Cancel
            </button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

CreateEditFunnelModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  funnel: PropTypes.object,
  isDuplicating: PropTypes.bool,
  onSaveSuccess: PropTypes.func,
  modalTitle: PropTypes.string,
}

export default CreateEditFunnelModal
